(function($) {
    if (typeof Swup !== "undefined") {
        const swup = new Swup({
            elements: ['#layout_main', '#layout_header'],
            doScrollingRightAway: false,
            animateScroll: false
        });

        swup.on('clickLink', function () {
            $(".ssm-nav-visible").removeClass("ssm-nav-visible");
            $(".ssm-overlay").fadeOut(300);
            $('html').css('overflow','visible');
            if ($(".lib--dialog").length) {
                nl_lib_dialog.close();
            }
        });

        swup.on('contentReplaced', function () {
            swup.options.elements.forEach((selector) => {
                Object.keys(window.lui.layout).forEach(function(key) {
                    if ($(key).length) {
                        window.lui.layout[key]("layout",key)
                    }
                });
                Object.keys(window.lui.comp).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.comp[key]("comp",key)
                    }
                });
                Object.keys(window.lui.part).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.part[key]("part",key)
                    }
                });
                Object.keys(window.lui.lib).forEach(function(key) {
                    let s = window.lui.lib[key].selector;
                    if ($(selector).find(s).length) {
                        window.lui.lib[key](key,$(selector).find(s))
                    }
                });
            });

            if (typeof fbq !== "undefined") {
                fbq('track', 'PageView');
            }

            if (typeof gtag !== "undefined") {
                let configs = [];
                window.dataLayer.forEach(function(config){
                    if (config[0] === "config") {
                        if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                            configs.push(config[1]);
                            gtag('config', config[1], {
                                'page_title': document.title,
                                'page_path': window.location.pathname + window.location.search
                            });
                        }
                    }
                });
            }

            if (typeof window.dataLayer !== "undefined") {
                window.dataLayer.push({
                    'event': 'VirtualPageview',
                    'virtualPageURL': window.location.pathname + window.location.search,
                    'virtualPageTitle': document.title
                });
            }

            if (typeof window.seznam_retargeting_id !== "undefined") {
                $.getScript("https://c.imedia.cz/js/retargeting.js");
            }

            if (document.querySelector('.part_form_cookieconsent') !== null) {
                document.documentElement.classList.add('is-cookie-page')
            } else {
                document.documentElement.classList.remove('is-cookie-page')
            }

            if (typeof LibCookieConsent !== 'undefined') {
                LibCookieConsent.init();
            }

            if (body.hasClass("body--preload")) {
                cssLoaded(function(){
                    body.removeClass("body--preload");
                    setTimeout(function () {
                        body.addClass("body--loaded");
                    }, 300);
                });
            }
        });
    }

    doc.on('change','[data-chart-change]',function() {
        let ajaxLink = $('.comp_chart_advanced').attr('data-ajax-link');

        let data = {
            'chartType': $('select[name="chartType"]').val(),
            'dateRange': $('select[name="dateRange"]').val(),
            'current': $('input[type="radio"][name="current"]:checked').val(),
        };

        $.ajax({
            method: 'post',
            url: ajaxLink,
            data: data,
            dataType: "json",
            cache: false,
            success: function (payload) {
                fn_ajaxHandler(payload,function() {
                    ScrollReveal().sync();
                });
            }
        });
    });

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }
    });

    lui_dialog.init(function(){
        Object.keys(window.lui.lib).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s).length) {
                window.lui.lib[key](key,$(".lib--dialog").find(s))
            }
        });

        Object.keys(window.lui.part).forEach(function(key) {
            if ($(`.lib--dialog ${key}`).length) {
                window.lui.part[key]("part",`.lib--dialog ${key}`)
            }
        });
    });

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().lui_parallax();
        });
    });

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_ripple", ".part_ui_btn, .part_ui_checkbox, .part_ui_radio", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        $.lib_flickity(function(){
            selector.each(function(){
                let elm = $(this);
                let options = elm.data("lib-flickity");
                let nav = elm.parent().find("[data-lib-flickity-nav]");
                let items = elm.children();

                elm.on("ready.flickity", function(e){
                    elm.find(".flickity-button").lui_ripple();
                    if (elm.find(".flickity-button[disabled]").length === 2) {
                        elm.addClass("flickity-nav-disabled");
                    }
                });

                let slider = $(this).flickity({
                    groupCells: (typeof options["groupCells"] !== "undefined") ? options["groupCells"] : "25%",
                    cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                    setGallerySize: true,
                    autoPlay: options["autoplay"],
                    pageDots: true,
                    wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                    contain: true,
                    pauseAutoPlayOnHover: true,
                    prevNextButtons: true,
                    initialIndex: (typeof options["initialIndex"] !== "undefined") ? options["initialIndex"] : 1
                });

                if (typeof options["flex"] !== "undefined") {
                    elm.addClass("flickity-flex").data("flickity").cells.forEach( function( cell ) {
                        cell.element.style.position = '';
                        cell.element.style.left = '';
                    });
                }

                if(items.length < 3) {
                    elm.addClass("flickity-no-nav");
                }

                if (nav.length) {
                    nav.on("click", function(){
                        let direction = $(this).data("lib-flickity-nav");

                        if (direction === "prev") {
                            slider.flickity('previous');
                        } else {
                            slider.flickity('next');
                        }
                    });
                }
            });
        });
    });

    $.lui("lib_map", "[data-lib-map]", function(selector){
        if(selector.closest("[data-lib-map-load]").data("lib-map-load") === true) {
            $.importScript(cdnjs.googlemap.replace("APIKEY", selector.data("lib-map")), function(){
                let zoom = selector.data("zoom");
                let coords = selector.data('coords').split(/[\s,]+/);
                let color = selector.data("color");

                let map = new google.maps.Map(selector[0], {
                    zoom: zoom,
                    center: {
                        lat: parseFloat(coords[0]), lng: parseFloat(coords[1])
                    },
                    mapTypeControl: false,
                    styles: [
                        {
                            "featureType": "all",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "saturation": 36
                                },
                                {
                                    "color": "#333333"
                                },
                                {
                                    "lightness": 40
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 17
                                },
                                {
                                    "weight": 1.2
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dedede"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 29
                                },
                                {
                                    "weight": 0.2
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 18
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f2f2f2"
                                },
                                {
                                    "lightness": 19
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e9e9e9"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        }
                    ]
                });

                let image = {
                    path: 'm49.129484 66.997979-3.966888 6.94085-3.966691-6.94085zm6.615849-3.864073H34.578666l10.585517 18.520833z',
                    fillColor: color,
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    fillOpacity: 1,
                    scale: 3,
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(45, 80)
                };

                let map_marker = new google.maps.Marker({
                    position: {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])},
                    map: map,
                    icon: image
                });

                google.maps.event.addListener(map_marker, 'click', function(){
                    window.open(selector.data("marker-url"), '_blank');
                });
            });
        }
    });

    $.lui("lib_autosize","[data-lib-autosize]",function (selector) {
        $.importScript(cdnjs.autosize,function () {
            autosize(selector);
        });
    });

    $.lui("lib_gallery","[data-lib-gallery]",function (selector) {
        $.importStyle(cdnjs.fancybox_css)
        $.importScript(cdnjs.fancybox,function () {

            Fancybox.bind('[data-lib-gallery-item]', {
                closeButton: 'outside',
                groupAttr: 'data-lib-gallery-item',
                l10n: {
                    CLOSE: "Zavřít",
                    NEXT: "Další",
                    PREV: "Předchozí",
                    MODAL: "Toto okno můžete zavřít stisknutím klávesy ESC",
                    ERROR: "Někde se něco pokazilo, prosím zkuste akci opakovat později",
                    IMAGE_ERROR: "Obrázek nenalezen",
                    ELEMENT_NOT_FOUND: "HTML element nenalezen",
                    AJAX_NOT_FOUND: "Chyba AJAX načítaní: Not Found",
                    AJAX_FORBIDDEN: "Chyba AJAX načítaní: Forbidden",
                    IFRAME_ERROR: "Stránku nelze načíst",
                    TOGGLE_ZOOM: "Přiblížit/Oddálit",
                    TOGGLE_THUMBS: "Zobrazit/Skrýt náhledy",
                    TOGGLE_SLIDESHOW: "Spoustit/Zastavit automatické přehrávání",
                    TOGGLE_FULLSCREEN: "Režim celé obrazovky",
                    DOWNLOAD: "Stáhnout",
                },
                Toolbar: {
                    display: ['counter', 'thumbs', 'close']
                },
                on: {
                    init: () => {
                        const offset = window.innerWidth - document.body.clientWidth
                        document.documentElement.style.setProperty('--overlay-offset', `${offset}px`)
                        document.documentElement.classList.add('is-overlay')
                    },
                    destroy: () => {
                        document.documentElement.classList.remove('is-overlay')
                    }
                }
            })
        });
    });

    setTimeout(function(){
        $.lui("lib_recaptcha", "[data-recaptcha]", function(selector) {
            $(selector).lib_recaptcha($(selector).data('recaptcha'));
        });
    },2000);

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let $el = $(this);

            $.ajax({
                url: $el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            frm.addClass('state--loading');

            frm.find('button[type=submit]').addClass('state--loading').attr('disabled', true);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload, function () {
                        window.lui.part[".part_ui_alert"]("part", "#snippet-newsletter .part_ui_alert");
                        ScrollReveal().sync();
                    });
                }
            }).always(function () {
                frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();

                    if(doc.find(".comp_articles").length) {
                        doc.find(".comp_articles").find(".wrp_comp_head").anchor_anim()
                    }
                });
            });
        });
    })();

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    doc.on('change', '[data-changeobdobi], [data-changetypy]', function(e){
        $('[data-changebutton]').trigger( "click" );
    });

})(jQuery);
