$.lui("comp", ".comp_calc", function (selector) {
    selector = selector[0]

    const target = {
        inputInvestmentReturnTarget: selector.querySelector('[data-calc-target="inputInvestmentReturn"]'),
        inputStartDepositTarget: selector.querySelector('[data-calc-target="inputStartDeposit"]'),
        // inputRegularDepositTarget: selector.querySelector('[data-calc-target="inputRegularDeposit"]'),
        inputInvestmentHorizonTarget: selector.querySelector('[data-calc-target="inputInvestmentHorizon"]'),
        outputDepositTotalTarget: selector.querySelector('[data-calc-target="outputDepositTotal"]'),
        outputValorizationTarget: selector.querySelector('[data-calc-target="outputValorization"]'),
        outputTotalTarget: selector.querySelector('[data-calc-target="outputTotal"]'),
        graphTarget: selector.querySelector('[data-calc-target="graph"]'),
        currencySwitchTarget: [...selector.querySelectorAll('[data-calc-target="currencySwitch"]')],
        defaultValuesTarget: selector.querySelector('[data-calc-target="defaultValues"]'),
        hiddenInputInvestmentReturnTarget: selector.querySelector('[data-calc-target="hiddenInputInvestmentReturn"]'),
        hiddenInputStartDepositTarget: selector.querySelector('[data-calc-target="hiddenInputStartDeposit"]'),
        // hiddenInputRegularDepositTarget: selector.querySelector('[data-calc-target="hiddenInputRegularDeposit"]'),
        hiddenInputInvestmentHorizonTarget: selector.querySelector('[data-calc-target="hiddenInputInvestmentHorizon"]'),
        hiddenOutputDepositTotalTarget: selector.querySelector('[data-calc-target="hiddenOutputDepositTotal"]'),
        hiddenOutputValorizationTarget: selector.querySelector('[data-calc-target="hiddenOutputValorization"]'),
        hiddenOutputTotalTarget: selector.querySelector('[data-calc-target="hiddenOutputTotal"]'),
        hiddenCurrencySymbolTarget: selector.querySelector('[data-calc-target="hiddenCurrencySymbol"]'),
    }

    const defaultValues = JSON.parse(target.defaultValuesTarget.innerHTML)

    let chart;

    const pluralRules = new Intl.PluralRules(document.documentElement.lang, {type: "cardinal"})

    function addDigitSeparator(n) {
        const rx = /(\d+)(\d{3})/;
        return String(n).replace(/^\d+/, function(w) {
            while (rx.test(w)) {
                w = w.replace(rx, '$1 $2');
            }
            return w;
        });
    }

    function setValue(input) {
        const inputWrapper = input.parentNode;
        const parentStyles = inputWrapper.style;
        inputWrapper.setAttribute('data-tooltip-text', addDigitSeparator(input.value));
        parentStyles.setProperty('--value', (input.value - input.min) / (input.max - input.min));

        if(typeof inputWrapper.dataset.unitPlurals !== 'undefined') {
            const suffixes = JSON.parse(inputWrapper.dataset.unitPlurals)
            const rule = pluralRules.select(input.value)
            const suffix = suffixes[rule]

            inputWrapper.setAttribute('data-unit', suffix);
        }

        if(inputWrapper.classList.contains('has-offset')) {
            const percentage = (input.value - input.min) / (input.max - input.min) * 100

            inputWrapper.classList.remove('is-offset-left','is-offset-right');

            if(percentage > 80) {
                inputWrapper.classList.add('is-offset-right');
            }

            if(percentage < 20) {
                inputWrapper.classList.add('is-offset-left');
            }
        }
    }

    function calculate() {
        let investmentReturn = parseInt(target.inputInvestmentReturnTarget.value);
        let startDeposit = parseInt(target.inputStartDepositTarget.value);
        let regularDeposit = 0;
        let investmentHorizon = parseInt(target.inputInvestmentHorizonTarget.value);

        let depositTotal = startDeposit + (regularDeposit * 12 * investmentHorizon);

        let valorization = Math.round(startDeposit * Math.pow(1 + investmentReturn / 100.0, investmentHorizon) + regularDeposit * (Math.pow(1 + (investmentReturn / 1200.0), investmentHorizon * 12) - 1) / (investmentReturn / 1200.0) * (1 + (investmentReturn / 1200.0)));

        valorization = valorization - depositTotal;

        return { depositTotal, valorization: (valorization) };
    }

    function setDefaultValues(currency) {
        target.inputStartDepositTarget.min = defaultValues[currency]['inputStartDepositMin'];
        target.inputStartDepositTarget.max = defaultValues[currency]['inputStartDepositMax'];
        target.inputStartDepositTarget.step = defaultValues[currency]['inputStartDepositStep'];
        target.inputStartDepositTarget.value = defaultValues[currency]['inputStartDepositValue'];
        target.inputInvestmentReturnTarget.min = defaultValues[currency]['inputInvestmentReturnMin'];
        target.inputInvestmentReturnTarget.max = defaultValues[currency]['inputInvestmentReturnMax'];
        target.inputInvestmentReturnTarget.value = defaultValues[currency]['inputInvestmentReturnValue'];

        target.inputStartDepositTarget.parentElement.dataset.legendText = addDigitSeparator(defaultValues[currency]['inputStartDepositMax']);
    }

    function changeCurrency() {
        target.currencySwitchTarget.forEach(input => {
            if(input.checked) {
                if(input.value === "CZK") {
                    selector.querySelectorAll('[data-unit="€"]').forEach(function(element) {
                        element.dataset.unit = 'Kč';

                        setDefaultValues('CZK');
                    });
                }

                if(input.value === "EUR") {
                    selector.querySelectorAll('[data-unit="Kč"]').forEach(function(element) {
                        element.dataset.unit = '€';
                    });

                    setDefaultValues('EUR');
                }
            }
        });

        selector.querySelectorAll('input[type="range"]').forEach(element => {
            setValue(element);
        })

        const { depositTotal, valorization } = calculate();

        recalculate(depositTotal, valorization);
    }

    function recalculate(depositTotal, valorization) {
        target.outputDepositTotalTarget.textContent = addDigitSeparator(depositTotal);
        target.outputValorizationTarget.textContent = addDigitSeparator(valorization);
        target.outputTotalTarget.textContent = addDigitSeparator(depositTotal + valorization);

        chart && chart.update({
            series: [depositTotal, valorization]
        })

        setHidden(depositTotal, valorization)
    }

    function setHidden(depositTotal, valorization) {
        // target.hiddenInputInvestmentReturnTarget.value = parseFloat(target.inputInvestmentReturnTarget.textContent.replace(',','.'));
        // target.hiddenInputStartDepositTarget.value = parseInt(target.inputStartDepositTarget.value);
        // target.hiddenInputRegularDepositTarget.value = parseInt(target.inputRegularDepositTarget.value);
        target.hiddenInputInvestmentReturnTarget.value = parseInt(target.inputInvestmentReturnTarget.value);
        target.hiddenInputInvestmentHorizonTarget.value = parseInt(target.inputInvestmentHorizonTarget.value);
        target.hiddenOutputDepositTotalTarget.value = addDigitSeparator(depositTotal);
        target.hiddenOutputValorizationTarget.value = addDigitSeparator(valorization);
        target.hiddenOutputTotalTarget.value = addDigitSeparator(depositTotal + valorization);
        target.hiddenCurrencySymbolTarget.value = selector.querySelector('[data-unit="Kč"]') ? 'Kč' : '€'
    }

    changeCurrency();

    target.currencySwitchTarget.forEach(input => {
        input.addEventListener('change', function() {
            changeCurrency();
        })
    })

    selector.querySelectorAll('input[type="range"]').forEach(element => {
        element.addEventListener('input', function(e) {
            const { depositTotal, valorization } = calculate();

            setValue(e.target);
            recalculate(depositTotal, valorization);
        })
    })

    let { depositTotal, valorization } = calculate();

    recalculate(depositTotal, valorization);

    $.importStyle(cdnjs.chartist_css);
    $.importScript(cdnjs.chartist, function () {
        chart = new Chartist.Pie(target.graphTarget, {
            series: [depositTotal, valorization]
        }, {
            donut: true,
            showLabel: false
        });
    })
})
