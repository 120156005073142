$.lui("part",".part_item_people",function (selector) {
    selector.on("click",function (e) {
        let el = $(this).closest(selector);
        if(win.width() < 1025 && !el.hasClass("state--toggled")) {
            e.stopPropagation();
            e.preventDefault();
            selector.not(el).removeClass("state--toggled");
            el.addClass("state--toggled");
        }
    })
});